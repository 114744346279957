import type { FormFrameParams, ResultsFrameParams } from 'common/frame-params';
import { serializeQueryString } from 'common/util/query-string';

type FrameParams = Record<string, string | number | boolean | undefined>;

/*

Methods for building the URLs to the iframes.

*/

const buildFrameUrl = (params: {
    frameHost: string;
    path: string;
    frameParams: FrameParams;
}): string => {
    const url = new URL(`${params.frameHost}${params.path}`);

    const queryString = serializeQueryString(params.frameParams);

    url.search = queryString.toString();

    return url.toString();
};

export const getFormFrameUrl = (params: {
    frameHost: string;
    frameParams: FormFrameParams;
}): string =>
    buildFrameUrl({
        frameHost: params.frameHost,
        path: '/form',
        frameParams: params.frameParams,
    });

export const getResultsFrameUrl = (params: {
    frameHost: string;
    frameParams: ResultsFrameParams;
}): string =>
    buildFrameUrl({
        frameHost: params.frameHost,
        path: '/results',
        frameParams: params.frameParams,
    });

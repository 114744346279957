import type {
    FormSubmittedEvent,
    NavigatedToExternalResultsEvent,
} from 'common/domain-events';
import { getLoadedFramePair } from 'static/frames/loaded-frames';
import { bubbleEvent } from './bubble-event';

// Send the user to an external results URL, adding the searchId and
// transactionId. Also send a NavigatedToExternalResults event to the parent
// page.
const gotoExternalResultsUrl = (params: {
    event: FormSubmittedEvent;
    externalUrl: string;
}): void => {
    const url = new URL(params.externalUrl);
    url.searchParams.set('ce_searchId', params.event.searchId);

    const navigationEvent: NavigatedToExternalResultsEvent = {
        eventName: 'navigating_to_external_results_location',
        capturedAt: Date.now(),
        url: url.toString(),
        searchId: params.event.searchId,
    };

    window.postMessage(navigationEvent, '*');

    window.location.href = url.toString();
};

// Add the searchId to a frame's query string
const addSearchIdToFrame = (params: {
    frame: HTMLIFrameElement;
    searchId: string;
}): void => {
    const url = new URL(params.frame.src);
    url.searchParams.set('searchId', params.searchId);
    params.frame.src = url.toString();
};

// Add the searchId to all result frames so that they reload and display the
// results.
const addSearchIdToResultFrames = (params: {
    pairKey: string;
    searchId: string;
}): void => {
    const framePair = getLoadedFramePair(params.pairKey);
    const resultsFrames = framePair?.results ?? [];
    resultsFrames.forEach((rf) => {
        addSearchIdToFrame({ frame: rf.frame, searchId: params.searchId });
    });
};

// Handle tasks triggered by a FormSubmitted event
export const handleFormSubmitted = (params: {
    event: FormSubmittedEvent;
}): void => {
    // Re-emit the event to our parent page
    bubbleEvent({
        event: params.event,
        extra: {
            accreditations: params.event.accreditations,
            degreeLevel: params.event.degreeLevel,
            fieldOfStudy: params.event.fieldOfStudy,
            major: params.event.major,
            modality: params.event.modality,
            programLength: params.event.programLength,
            requirements: params.event.requirements,
            transactionId: params.event.transactionId,
        },
    });

    // Don't do anything else unless we had results
    if (params.event.numResults === 0) return;

    // If the search was configured with an externalResultsURL, send the user to
    // that URL (adding the searchId and transactionId as query parameters)
    if (params.event.externalResultsURL) {
        gotoExternalResultsUrl({
            event: params.event,
            externalUrl: params.event.externalResultsURL,
        });
        return;
    }

    // Otherwise, add the searchId to all result frames so that they reload and
    // display the results.
    addSearchIdToResultFrames({
        pairKey: params.event.pairKey,
        searchId: params.event.searchId,
    });
};

import { assertUnreachable } from '@libraries/assert-unreachable';
import type { DomainEvent } from 'common/domain-events';
import { domainEventSchema, pageLoadedEventSchema } from 'common/domain-events';
import type { PolymorphicWindow } from 'common/types/window';
import { handleAdClicked } from 'static/event-handler/ad-clicked';
import { handleAdLoaded } from 'static/event-handler/ad-loaded';
import { bubbleEvent } from 'static/event-handler/bubble-event';
import { handleCdoViewMoreClicked } from 'static/event-handler/cdo-view-more-clicked';
import { handleFormDropdownClosed } from 'static/event-handler/form-dropdown-closed';
import { handleFormDropdownOpened } from 'static/event-handler/form-dropdown-opened';
import { handleFormHeightChanged } from 'static/event-handler/form-height-changed';
import { handleFormLoaded } from 'static/event-handler/form-loaded';
import { handleFormSubmitted } from 'static/event-handler/form-submitted';
import { handlePageLoaded } from 'static/event-handler/page-loaded';
import { handleResultsHeightChanged } from 'static/event-handler/results-height-changed';
import { handleResultsLoaded } from 'static/event-handler/results-loaded';
import { handleSearchById } from 'static/event-handler/search-by-id';
import { handleSearchCompleted } from 'static/event-handler/search-completed';
import { handleSearchOnLoad } from 'static/event-handler/search-on-load';

// Skip handling of events from other origins -- unless it's a page_loaded
// event, since the origin is the parent page.
const ignoreEvent = (params: {
    event: MessageEvent<unknown>;
    frameHost: string;
    domainEvent: DomainEvent;
}): boolean => {
    if (pageLoadedEventSchema.safeParse(params.domainEvent).success)
        return false;

    return params.event.origin !== params.frameHost;
};

// Run appropriate handler for a domain event.
const runHandler = (params: {
    event: DomainEvent;
    window: PolymorphicWindow;
}): void => {
    const { event } = params;
    switch (event.eventName) {
        case 'ad_clicked': {
            handleAdClicked({ event });
            return;
        }

        case 'ad_loaded': {
            handleAdLoaded({ event });
            return;
        }

        case 'cdo_view_more_clicked': {
            handleCdoViewMoreClicked({ event });
            return;
        }

        case 'form_dropdown_closed': {
            handleFormDropdownClosed({ event });
            return;
        }

        case 'form_dropdown_opened': {
            handleFormDropdownOpened({ event });
            return;
        }

        case 'form_height_changed': {
            handleFormHeightChanged({ event });
            return;
        }

        case 'form_loaded': {
            handleFormLoaded({ event });
            return;
        }

        case 'form_submitted': {
            handleFormSubmitted({ event });
            return;
        }

        case 'navigating_to_external_results_location': {
            bubbleEvent({ event });
            return;
        }

        case 'no_offers_found': {
            bubbleEvent({ event });
            return;
        }

        case 'page_loaded': {
            handlePageLoaded({ event });
            return;
        }

        case 'results_height_changed': {
            handleResultsHeightChanged({ event });
            return;
        }

        case 'results_loaded': {
            handleResultsLoaded({ event });
            return;
        }

        case 'search_by_id': {
            handleSearchById({ event });
            return;
        }

        case 'search_completed': {
            handleSearchCompleted({ event });
            return;
        }

        case 'search_on_load': {
            handleSearchOnLoad({ event });
            return;
        }

        // case 'test_triggered':
        //     return handleTestTriggered({ event, window });

        case 'view_more_clicked': {
            bubbleEvent({ event });
            return;
        }

        default:
            assertUnreachable(event);
    }
};

export const handleDomainEvent = (params: {
    event: MessageEvent<unknown>;
    frameHost: string;
    window: PolymorphicWindow;
    debug?: boolean;
}): void => {
    // Parse the event and ignore it if it's not a domain event.
    const parseResult = domainEventSchema.safeParse(params.event.data);
    if (!parseResult.success) return;
    const domainEvent = parseResult.data;

    // Possibly ignore the event.
    if (ignoreEvent({ ...params, domainEvent })) return;

    if (params.debug) {
        console.log('Morpheus event:', JSON.stringify(domainEvent, null, 2));
    }

    runHandler({ event: domainEvent, window: params.window });
};

import type { PolymorphicWindow } from 'common/types/window';

export type BrowserConfig = {
    parentPageDomain: string;
    parentPageUrl: string;
};

// Get some config variables from the browser environment.
export const getBrowserConfig = (params: {
    window: PolymorphicWindow;
}): BrowserConfig => {
    const href = params.window.location.href;
    const parentPageUrl = href;
    const url = new URL(href);
    const parentPageDomain = url.hostname;

    return {
        parentPageDomain,
        parentPageUrl,
    };
};

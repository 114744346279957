import type { ResultsFrameParams } from 'common/frame-params';
import type { PolymorphicWindow } from 'common/types/window';
import { insertFrame } from 'static/frames/insert-frame';
import { saveResultsFrame } from 'static/frames/loaded-frames';
import type { HashConfig } from 'static/hash-config';
import type { Logger } from 'static/logger';
import { ResultsFrameParamsBuilder } from './results-frame-params-builder';
import { getResultsFrameUrl } from './url';

/*

Configures and inserts an iframe for the /results route.

*/

let RESULT_KEY = 0;

// Create the parameters to send to the iframe holding the /results route. By
// typing this function as ResultsFrameParams, we can ensure that we're passing
// the correct parameters to the iframe. c.f. app/routes/results.tsx
export const getResultsFrameParams = (params: {
    window: PolymorphicWindow;
    transactionId: string;
    element: HTMLElement;
    logger: Logger;
    resultKey: number;
    hashConfig: HashConfig;
}): ResultsFrameParams => {
    const builder = new ResultsFrameParamsBuilder(params);
    return builder.getFrameParams();
};

export const initResultsFrame = (params: {
    frameHost: string;
    transactionId: string;
    element: HTMLElement;
    hashConfig: HashConfig;
}): void => {
    const resultKey = RESULT_KEY++;

    const frameParams = getResultsFrameParams({
        window,
        transactionId: params.transactionId,
        element: params.element,
        logger: console,
        resultKey,
        hashConfig: params.hashConfig,
    });

    const url = getResultsFrameUrl({
        frameHost: params.frameHost,
        frameParams,
    });

    const frame = insertFrame({
        url,
        parentEl: params.element,
        position: 'relative',
        height: '0px',
    });

    saveResultsFrame({
        pairKey: frameParams.pairKey,
        transactionId: frameParams.transactionId,
        frame,
        searchOnLoad: Boolean(frameParams.searchOnLoad),
        resultKey,
        logger: console,
    });

    params.element.style.overflow = 'hidden';
    params.element.style.width = '100%';
};

export const deepEqual = (obj1: unknown, obj2: unknown): boolean => {
    if (obj1 === obj2) {
        return true;
    }

    if (
        typeof obj1 !== 'object' ||
        typeof obj2 !== 'object' ||
        obj1 == null ||
        obj2 == null
    ) {
        return false;
    }

    // Type assertion since we've verified these are non-null objects
    const obj1AsRecord = obj1 as Record<string, unknown>;
    const obj2AsRecord = obj2 as Record<string, unknown>;

    const keys1 = Object.keys(obj1AsRecord);
    const keys2 = Object.keys(obj2AsRecord);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (!keys2.includes(key)) {
            return false;
        }

        if (!deepEqual(obj1AsRecord[key], obj2AsRecord[key])) {
            return false;
        }
    }

    return true;
};

import { getErrorMessage } from '@libraries/get-error-message';
import { z } from 'zod';
import { fromZodError } from 'zod-validation-error';

/*

Parses out a record from an HTML element's "data-archer-config" attribute that is then used (after further parsing) to configure a frame.

*/

const getElementDesc = (element: HTMLElement): string => {
    if (element.id) return `#${element.id}`;
    const classNames = Array.from(element.classList);
    if (classNames.includes('archer-form')) return 'archer-form element';
    if (classNames.includes('archer-results')) return 'archer-results element';
    return 'unknown element';
};

export const parseElementDataAttribute = (
    element: HTMLElement,
): Record<string, unknown> => {
    const elementDesc = getElementDesc(element);
    if (!element.dataset.archerConfig)
        throw new Error(`${elementDesc} has no "data-archer-config" attribute`);

    let elementConfig;
    try {
        elementConfig = JSON.parse(element.dataset.archerConfig);
    } catch (error) {
        const msg = getErrorMessage(error);
        throw new Error(
            `Invalid JSON in "data-archer-config" at ${elementDesc}: ${msg}`,
        );
    }

    const parseResult = z
        .record(z.string(), z.unknown())
        .safeParse(elementConfig);

    if (!parseResult.success) {
        const msg = fromZodError(parseResult.error);
        throw new Error(
            `Invalid "data-archer-config" at ${elementDesc}: ${msg.toString()}`,
        );
    }

    return parseResult.data;
};

import type { DomainEvent } from 'common/domain-events';

const getFrameOptions = () => {
    const formElements = document.querySelectorAll<HTMLElement>('.archer-form');
    const resultElements =
        document.querySelectorAll<HTMLElement>('.archer-results');

    return {
        forms: [...formElements].map(
            ({ dataset: { archerConfig } }) =>
                archerConfig && JSON.parse(archerConfig),
        ),
        results: [...resultElements].map(
            ({ dataset: { archerConfig } }) =>
                archerConfig && JSON.parse(archerConfig),
        ),
    };
};

// Re-emit event to parent page, with some added details
export const bubbleEvent = (params: {
    event: DomainEvent;
    extra?: Record<
        string,
        | string
        | boolean
        | number
        | Array<string>
        | Array<Record<string, string>>
        | undefined
    >;
}): void => {
    const customEvent = new CustomEvent(`archer:${params.event.eventName}`, {
        bubbles: true,
        detail: {
            eventName: params.event.eventName,
            capturedAt: params.event.capturedAt,
            frameOptions: getFrameOptions(),
            pageUrl: document.location.href,
            referrerUrl: document.referrer,
            ...(params.extra ?? {}),
        },
    });

    document.dispatchEvent(customEvent);
};

import type { ResultsHeightChangedEvent } from 'common/domain-events';
import { getLoadedFramePair } from 'static/frames/loaded-frames';

// On a ResultsHeightChanged event, resize the relevant results frame
export const handleResultsHeightChanged = (params: {
    event: ResultsHeightChangedEvent;
}): void => {
    const resultKey = params.event.resultKey;
    const framePair = getLoadedFramePair(params.event.pairKey);
    const resultsFrames = framePair?.results ?? [];
    const resultFrame = resultsFrames.find((frame) => frame.key === resultKey);
    if (!resultFrame) return;
    resultFrame.frame.style.height = `${params.event.height}px`;
};

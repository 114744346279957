import type { PageLoadedEvent } from 'common/domain-events';

export const emitPageLoadedEvent = (): void => {
    const pageLoaded: PageLoadedEvent = {
        eventName: 'page_loaded',
        capturedAt: Date.now(),
        userAgent: navigator.userAgent,
    };

    window.postMessage(pageLoaded, '*');
};

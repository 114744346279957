import type { PolymorphicWindow } from 'common/types/window';
import { handleDomainEvent } from 'static/event-handler';

export const addEventListenerToParentPage = (params: {
    frameHost: string;
    window: PolymorphicWindow;
    debug?: boolean;
}): void => {
    const { frameHost, debug } = params;
    window.addEventListener('message', (event) => {
        handleDomainEvent({ event, frameHost, window: params.window, debug });
    });
};

import { z } from 'zod';

export const discardEmptyString = (val: unknown): unknown =>
    val === '' ? undefined : val;

export const optionalStringStripEmpty = z
    .string()
    .optional()
    .transform((val) => (val === '' ? undefined : val));

export const booleanCoerceString = z.preprocess((val) => {
    if (typeof val === 'string') return val.toLowerCase() === 'true';
    return val;
}, z.boolean());

const stripOptional = (zodDef: z.ZodTypeAny): z.ZodTypeAny => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (zodDef instanceof z.ZodOptional) return stripOptional(zodDef.unwrap());
    return zodDef;
};

const isBooleanDef = (zodDef: z.ZodTypeAny): boolean =>
    stripOptional(zodDef) instanceof z.ZodBoolean;

const isNumberDef = (zodDef: z.ZodTypeAny): boolean =>
    stripOptional(zodDef) instanceof z.ZodNumber;

export const getBooleanFields = (objectSchema: {
    shape: Record<string, z.ZodTypeAny>;
}): Array<string> => {
    const fields: Array<string> = [];
    Object.entries(objectSchema.shape).forEach(([field, def]) => {
        if (isBooleanDef(def)) {
            fields.push(field);
        }
    });

    return fields;
};

export const getNumberFields = (objectSchema: {
    shape: Record<string, z.ZodTypeAny>;
}): Array<string> => {
    const fields: Array<string> = [];
    Object.entries(objectSchema.shape).forEach(([field, def]) => {
        if (isNumberDef(def)) {
            fields.push(field);
        }
    });

    return fields;
};

// A schema wrapper for handling form submissions. It will convert empty
// strings, nulls, and '-' to undefined before validation.
export const formField = <Schema extends z.ZodTypeAny>(
    schema: Schema,
): z.ZodEffects<Schema> =>
    z.preprocess((val) => {
        if (val === null) return undefined;
        if (val === '') return undefined;
        if (val === '-') return undefined;
        return val;
    }, schema);

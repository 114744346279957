import type { CdoViewMoreClickedEvent } from 'common/domain-events';

// On a CdoViewMoreClicked event, send the user to a specific URL
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleCdoViewMoreClicked = (params: {
    event: CdoViewMoreClickedEvent;
}): void => {
    window.location.href =
        'https://www.counselingdegreesonline.org/search-programs';
};

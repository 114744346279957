import type { ResultsLoadedEvent } from 'common/domain-events';
import { getLoadedFramePair } from 'static/frames/loaded-frames';
import { searchParamsAreEqual } from 'static/util/url';
import { bubbleEvent } from './bubble-event';

// Change a frame to show or hide the privacy policy
const setPrivacyPolicy = (params: {
    frame: HTMLIFrameElement;
    value: boolean;
}): void => {
    const frameUrl = new URL(params.frame.src);
    const newUrl = new URL(params.frame.src);
    newUrl.searchParams.set(
        'showPrivacyPolicy',
        params.value ? 'true' : 'false',
    );
    if (searchParamsAreEqual(frameUrl, newUrl)) return;
    params.frame.src = newUrl.toString();
};

// Show the privacy policy in the form frame and not in the results frames
const swapPrivacyToForm = (params: { pairKey: string }): void => {
    const framePair = getLoadedFramePair(params.pairKey);
    const formFrame = framePair?.form;
    const resultsFrames = framePair?.results ?? [];
    if (formFrame) setPrivacyPolicy({ frame: formFrame, value: true });
    resultsFrames.forEach((rf) => {
        setPrivacyPolicy({ frame: rf.frame, value: false });
    });
};

// Does a frame have searchOnLoad set to true?
const isSearchOnLoadFrame = (frame: HTMLIFrameElement): boolean => {
    const frameUrl = new URL(frame.src);
    return frameUrl.searchParams.get('searchOnLoad') === 'true';
};

// Set the searchId in a frame's URL if it doesn't match the provided searchId
const setFrameSearchId = (params: {
    frame: HTMLIFrameElement;
    searchId: string;
}): void => {
    const frameUrl = new URL(params.frame.src);
    const currentSearchId = frameUrl.searchParams.get('searchId');
    if (currentSearchId === params.searchId) return;
    frameUrl.searchParams.set('searchId', params.searchId);

    params.frame.src = frameUrl.toString();
};

// Make sure all of our results frames have our searchId. Ignore frames
// configured as searchOnLoad.
const syncSearchId = (params: { pairKey: string; searchId: string }): void => {
    const framePair = getLoadedFramePair(params.pairKey);
    const resultsFrames = framePair?.results ?? [];
    resultsFrames.forEach((rf) => {
        if (isSearchOnLoadFrame(rf.frame)) return;
        setFrameSearchId({ frame: rf.frame, searchId: params.searchId });
    });
};

const isFrameOnScreen = (params: {
    pairKey: string;
    resultKey: number;
}): boolean => {
    const framePair = getLoadedFramePair(params.pairKey);
    const resultsFrames = framePair?.results ?? [];
    const resultFrame = resultsFrames.find((rf) => rf.key === params.resultKey);
    if (!resultFrame) return false;
    const yPosition = resultFrame.frame.getBoundingClientRect().top;
    return yPosition <= window.innerHeight;
};

// Handle tasks triggered by a ResultsLoaded event
export const handleResultsLoaded = (params: {
    event: ResultsLoadedEvent;
}): void => {
    // If we have no results, we want the form frame to show the privacy policy
    if (params.event.numResults === 0)
        swapPrivacyToForm({ pairKey: params.event.pairKey });

    // Make sure all of our other results frames have our searchId. This will
    // load the search into sibling frames when using split results (via the use
    // of resultsLoadRange).
    syncSearchId({
        pairKey: params.event.pairKey,
        searchId: params.event.searchId,
    });

    // Re-emit the event to our parent page, with added data that says whether
    // the form is on screen
    const onScreen = isFrameOnScreen({
        pairKey: params.event.pairKey,
        resultKey: params.event.resultKey,
    });
    bubbleEvent({
        event: params.event,
        extra: {
            clickoutSearchResultItemIds:
                params.event.clickoutSearchResultItemIds,
            height: params.event.height,
            numResults: params.event.numResults,
            onScreen,
            searchId: params.event.searchId,
            transactionId: params.event.transactionId,
            width: params.event.width,
        },
    });
};

const getHashParamValue = (params: Array<string>, paramName: string) => {
    const name = paramName + '=';
    const param = params.find((param) => param.startsWith(name));
    const value = param?.slice(name.length);

    return value;
};

export type HashConfig = {
    debug?: boolean;
    noFilter?: boolean;
    mockApi?: boolean;
    zip?: string;
    host?: string;
    themeId?: string;
};

export const getHashConfig = (hash: string): HashConfig => {
    const hashCode = '#campusexplorer/';

    if (!hash.startsWith(hashCode)) return {};

    const hashParams = hash.slice(hashCode.length).split('/');
    return {
        debug: hashParams.includes('debug'),
        noFilter: hashParams.includes('noFilter'),
        mockApi: hashParams.includes('mockApi'),
        zip: getHashParamValue(hashParams, 'zip'),
        host: getHashParamValue(hashParams, 'host'),
        themeId: getHashParamValue(hashParams, 'themeId'),
    };
};

import type { SearchCompletedEvent } from 'common/domain-events';
import { bubbleEvent } from './bubble-event';

export const handleSearchCompleted = (params: {
    event: SearchCompletedEvent;
}): void => {
    bubbleEvent({
        event: params.event,
        extra: {
            transactionId: params.event.transactionId,
            searchId: params.event.searchId,
            zipCode: params.event.zipCode,
            results: params.event.results,
        },
    });
};

import type { FormHeightChangedEvent } from 'common/domain-events';
import { getLoadedFramePair } from 'static/frames/loaded-frames';

const setFrameWidth = (frame: HTMLIFrameElement): void => {
    requestAnimationFrame(() => {
        if (frame.parentElement)
            frame.style.width = `${frame.parentElement.clientWidth}px`;
    });
};

// On a FormHeightChanged event, resize the form frame
export const handleFormHeightChanged = (params: {
    event: FormHeightChangedEvent;
}): void => {
    const framePair = getLoadedFramePair(params.event.pairKey);
    const formFrame = framePair?.form;
    if (!formFrame?.parentElement) return;
    formFrame.parentElement.style.height = `${params.event.height}px`;
    window.addEventListener('resize', () => {
        setFrameWidth(formFrame);
    });
    setFrameWidth(formFrame);
};

import type { FormFrameParams } from 'common/frame-params';
import type { PolymorphicWindow } from 'common/types/window';
import { toFullUrl } from 'common/util/url';
import { getBrowserConfig } from 'static/frames/config/browser-config';
import type { FormElementConfig } from 'static/frames/config/element-config';
import { formElementConfigSchema } from 'static/frames/config/element-config';
import { getPairKey } from 'static/frames/config/get-pair-key';
import { parseElementDataAttribute } from 'static/frames/config/parse-element-data-attribute';
import { parseConfigFromQs } from 'static/frames/config/qs-config';
import { insertFrame } from 'static/frames/insert-frame';
import { saveFormFrame } from 'static/frames/loaded-frames';
import { getFormFrameUrl } from 'static/frames/url';
import type { HashConfig } from 'static/hash-config';
import type { Logger } from 'static/logger';
import { fromZodError } from 'zod-validation-error';

/*

Configures and inserts an iframe for the /form route.

*/

// Transform the externalResultsURL to include the overriding
// publisherSourceCode and publisherTrackingCode if they exist. Also make sure
// it's fully qualified.
const getExternalResultsUrl = (params: {
    window: PolymorphicWindow;
    externalResultsURL?: string;
    publisherSourceCode?: string;
    publisherTrackingCode?: string;
}) => {
    if (!params.externalResultsURL) return undefined;
    const url = toFullUrl({
        window: params.window,
        path: params.externalResultsURL,
    });

    if (params.publisherSourceCode)
        url.searchParams.set('ces', params.publisherSourceCode);

    if (params.publisherTrackingCode)
        url.searchParams.set('cet', params.publisherTrackingCode);

    return url.toString();
};

// Parse and validate the element's config object in its "archerConfig" data
// attribute
const parseElementParams = (params: {
    element: HTMLElement;
}): FormElementConfig => {
    const elementConfig = parseElementDataAttribute(params.element);
    const parseResult = formElementConfigSchema.safeParse(elementConfig);
    if (parseResult.success) return parseResult.data;
    const msg = fromZodError(parseResult.error);
    throw new Error(`Invalid archerConfig form config: ${msg.toString()}`);
};

// Create the parameters to send to the iframe holding the /form route. By
// typing this function as FormFrameParams, we can ensure that we're passing the
// correct parameters to the iframe. c.f. app/routes/form.tsx
export const getFormFrameParams = (params: {
    window: PolymorphicWindow;
    element: HTMLElement;
    logger: Logger;
    transactionId: string;
    hashConfig: HashConfig;
}): FormFrameParams => {
    // Get config vars defined in the HTML element
    const elementConfig = parseElementParams(params);

    // Get config vars defined in the page's querystring
    const qsConfig = parseConfigFromQs({
        window: params.window,
        logger: params.logger,
    });

    const publisherTrackingCode =
        qsConfig.ce_publisherTrackingCode ??
        elementConfig.publisherTrackingCode;
    const publisherSourceCode =
        qsConfig.ce_publisherSourceCode ?? elementConfig.publisherSourceCode;

    // Transform the externalResultsURL to include publisher codes
    const externalResultsURL = getExternalResultsUrl({
        window: params.window,
        externalResultsURL: elementConfig.externalResultsURL,
        publisherSourceCode,
        publisherTrackingCode,
    });

    // Get some extra config from the browser environment
    const browserConfig = getBrowserConfig({ window: params.window });

    return {
        accreditations:
            qsConfig.ce_accreditations ?? elementConfig.accreditations,
        advertiserDedupe:
            qsConfig.ce_advertiserDedupe ?? elementConfig.advertiserDedupe,
        debug: params.hashConfig.debug,
        degree: qsConfig.ce_degree ?? elementConfig.degree,
        ep: qsConfig.ce_ep,
        externalResultsURL,
        fieldOfStudy: qsConfig.ce_fieldOfStudy ?? elementConfig.fieldOfStudy,
        hideDegree: elementConfig.hideDegree,
        hideFieldOfStudy: elementConfig.hideFieldOfStudy,
        hideMajor: elementConfig.hideMajor,
        major: qsConfig.ce_major ?? elementConfig.major,
        modality: qsConfig.ce_modality ?? elementConfig.modality,
        pairKey: getPairKey({
            pairKey: elementConfig.pairKey,
            logger: params.logger,
        }),
        parentPageDomain: browserConfig.parentPageDomain,
        parentPageUrl: browserConfig.parentPageUrl,
        placementType: qsConfig.ce_placementType ?? elementConfig.placementType,
        programLength: qsConfig.ce_programLength ?? elementConfig.programLength,
        publisherSourceCode,
        publisherTrackingCode,
        requirements: qsConfig.ce_requirements ?? elementConfig.requirements,
        searchId: qsConfig.ce_searchId,
        showPrivacyPolicy: false,
        test: qsConfig.ce_test ?? elementConfig.test,
        themeId: params.hashConfig.themeId ?? elementConfig.themeId,
        transactionId: params.transactionId,
        zipCode: qsConfig.ce_archeruserzip ?? elementConfig.archeruserzip,
    };
};

export const initFormFrame = (params: {
    frameHost: string;
    element: HTMLElement;
    transactionId: string;
    hashConfig: HashConfig;
}): void => {
    const frameParams = getFormFrameParams({
        window,
        element: params.element,
        logger: console,
        transactionId: params.transactionId,
        hashConfig: params.hashConfig,
    });

    const url = getFormFrameUrl({
        frameHost: params.frameHost,
        frameParams,
    });

    const frame = insertFrame({
        url,
        parentEl: params.element,
        position: 'absolute',
        height: '100%',
    });

    saveFormFrame({
        pairKey: frameParams.pairKey,
        transactionId: frameParams.transactionId,
        frame,
    });
};
